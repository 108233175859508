export default [

  {
  	path: 'myPayRecode',
  	name: 'MyPayRecode',
    meta: {
  		title: '我的资产',
  		icon:require('@/static/icons/user0.png'),
  		selectIcon:require('@/static/icons/userSelect0.png'),
    },
  	component: () => import('@/components/personalCenter/MyPayRecode.vue')
  },
	{
	path: 'myAttention',
	name: 'MyAttention',
	  meta: {
		title: '我的关注',
		icon:require('@/static/icons/user4.png'),
		selectIcon:require('@/static/icons/userSelect4.png'),
	  },
	component: () => import('@/components/personalCenter/MyAttention.vue'),
	children: []
	},
  {
	path: 'mySect',
	name: 'MySect',
    meta: {
		title: '我的门派',
		icon:require('@/static/icons/user1.png'),
		selectIcon:require('@/static/icons/userSelect1.png'),
    },
	component: () => import('@/components/personalCenter/MySect.vue')
  },
  {
  	path: 'myPublication',
  	name: 'MyPublication',
    meta: {
  		title: '我的发表',
			icon:require('@/static/icons/user5.png'),
			selectIcon:require('@/static/icons/userSelect5.png'),
    },
  	component: () => import('@/components/personalCenter/MyPublication.vue')
  },
  {
  	path: 'mySupport',
  	name: 'MySupport',
    meta: {
  		title: '我的打赏',
  		icon:require('@/static/icons/user8.png'),
  		selectIcon:require('@/static/icons/userSelect8.png'),
    },
  	component: () => import('@/components/personalCenter/MySupport.vue')
  },
	{
		path: 'myGuessBall',
		name: 'MyGuessBall',
	  meta: {
			title: '我的猜球',
			icon:require('@/static/icons/user6.png'),
			selectIcon:require('@/static/icons/userSelect6.png'),
	  },
		component: () => import('@/components/personalCenter/MyGuessBall.vue')
	},
  {
  path: 'myAccount',
  name: 'MyAccount',
  component: () => import('@/components/personalCenter/MyAccount.vue'),
  meta: {
      title: '账户设置',
  		icon:require('@/static/icons/user3.png'),
  		selectIcon:require('@/static/icons/userSelect3.png'),
  }
  },
  {
  	path: 'myMessage',
  	name: 'MyMessage',
    meta: {
  		title: '站内信 ',
  		icon:require('@/static/icons/user9.png'),
  		selectIcon:require('@/static/icons/userSelect9.png'),
    },
  	component: () => import('@/components/personalCenter/MyMessage.vue')
  },
  {
  	path: 'myTopping',
  	name: 'MyTopping',
    meta: {
  		title: '方案置顶推广',
  		icon:require('@/static/icons/user10.png'),
  		selectIcon:require('@/static/icons/userSelect10.png'),
    },
  	component: () => import('@/components/personalCenter/MyTopping.vue')
  },
	{
		path: 'withdrawalRecords',
		name:'WithdrawalRecords',
		meta: {
			title: '提现记录'
		},
		component: () => import('@/components/personalCenter/WithdrawalRecords.vue'),
	},
	{
		path: 'setting',
		name:'Setting',
		meta: {
			title: '个性设置'
		},
		component: () => import('@/components/personalCenter/Setting.vue'),
	}
]
