import Vue from 'vue'
import axios from 'axios';
import { Message, Loading } from 'element-ui';
import base_url from "./baseUrl";
import Qs from 'qs'

// import store from '@/store'//vuex的路径
Vue.use(Loading)
let baseURL = ''
if (process.env.NODE_ENV == 'development') {//development---开发环境
  // console.log(process.env.NODE_ENV,'process.env.NODE_ENV9999999999999');
  baseURL = base_url;
} else {
	baseURL = base_url;
  // baseURL = "https://p.admin.hphzjjh.com/api"//----生产环境
}
let loading;
export function startLoading() {
    loading  = Loading.service({
		body:true,
        // lock:true, //是否锁定
        text:'加载中...',//加载中需要显示的文字
        // background:'rgba(0,0,0,.7)',//背景颜色
    });
}
//结束加载动画
export function endLoading() {
    loading.close();
}

const instance = axios.create({
    baseURL,
	// timeout: 5000// 设置请求超时
})

// 请求拦截器
instance.interceptors.request.use(function (config) {
	// console.log(config,777);
	if (config.method=='post'||config.method=='upload') {
		config.headers['token'] = localStorage.getItem('token')//请求头
		// console.log(config)
	}else if(config.url=='my/getFocusUser'||config.url=='my/getFocusPub'||config.url=='my/getGuessCount'||
		config.url=='my/delUser'||config.url=='pub/pubHots'||config.url=='pub/pubNews'){
		config.headers['token'] = localStorage.getItem('token')//请求头
	}else if(config.url.indexOf('my/getGuess')!=-1||config.url.indexOf('my/getMsg')!=-1||
	config.url.indexOf('my/getSupport')!=-1||config.url.indexOf('theme/themeHits')!=-1||
	config.url.indexOf('theme/editTheme')!=-1||config.url.indexOf('my/getTheme')!=-1||
	config.url.indexOf('my/getRead')!=-1||config.url.indexOf('theme/checkPayTheme')!=-1||
	config.url.indexOf('recharge/checkPayOrder')!=-1||config.url.indexOf('pub/delNoPub')!=-1
	||config.url.indexOf('my/getPayGuess')!=-1||config.url.indexOf('my/tkRecode')!=-1
	||config.url.indexOf('my/delTk')!=-1||config.url.indexOf('theme/show')!=-1||
	config.url.indexOf('home/FPubArticleList')!=-1||config.url.indexOf('my/trueTk')!=-1
	||config.url.indexOf('my/promotionList')!=-1||config.url.indexOf('my/askquestion')!=-1||
	config.url.indexOf('my/settinglist')!=-1||config.url.indexOf('Alipay/checkOrder')!=-1){
		config.headers['token'] = localStorage.getItem('token')//请求头
	}
	// if (config.url!='pub/checkPubName') {
	// 	if (config.url.indexOf('home/getjf_arr')!=-1) {
			
	// 	}else {
	// 		startLoading();//请求时的加载动画
	// 	}
		
	// }
	
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(async function (res) {
	// endLoading();//结束加载动画
  return res;
}, function (error) {
  // 对响应错误做点什么
  // endLoading();//结束加载动画
  return Promise.reject(error);
});

function get(url,params,) {
  return instance.get(url, {params})
}
function put(url) {
  return instance.put(url,)
}
function post(url,data, params) {
  return instance.post(url, Qs.stringify(data), {params})
}
function upload(url, data) {//给上传图片的请求接口路径
  return instance.post(url, data)
}

export default { get, post, upload, put }
