import personal from '@/router/personal'
export default [
  {
	path: '/firstPage',
	name: 'FirstPage',
	component: () => import('@/components/FirstPage.vue'),
	meta: {
	    title: '首页',
      seotitle:'足球推荐_足球分析_足球比分-赢体育'
	},
	children: []
  },
  {
	path: '/expert',
	name: 'Expert',
	  meta: {
		title: '专家',
		seotitle:'竞彩专家_竞彩专家推荐-赢体育',
		keywords:'竞彩专家,竞彩专家推荐',
		description:'赢体育，为您提供海量高强竞彩专家，让您的竞彩一触即发！足彩篮彩竞彩专家比分预测平台'

	  },
	component: () => import('@/components/postBar/Expert.vue'),
	children: []
	},
  {
	path: '/footBall',
	name: 'FootBall',
    meta: {
		title: '赛事'
    },
	component: () => import('@/components/balls/FootBall.vue'),
	children: []
  },

	{
		path: '/tieba',
		name: 'Tieba',
		  meta: {
			title: '方案',
			seotitle:'竞彩方案_竞彩预测方案-赢体育',
			keywords:'竞彩方案,竞彩预测方案',
			description:'赢体育，为您提供海量精准的竞彩方案，竞彩预测方案，让您的竞彩一触即发！足彩篮彩竞彩专家比分预测平台'
		  },
		component: () => import('@/components/postBar/Tieba.vue'),
		children: []
		},
  // {
  // 	path: '/basketBall',
  // 	name: 'BasketBall',
  //   meta: {
  // 		title: '篮球'
  //   },
  // 	component: () => import('@/components/balls/BasketBall.vue'),
  // 	children: []
  // },
  /*{
  	path: '/guessBall',
  	name: 'GuessBall',
    meta: {
  		title: 'Y猜球',
      seotitle:'Y猜球_Y江湖_赢体育'
    },
  	component: () => import('@/components/balls/GuessBall.vue'),
  	children: []
  },*/
  {
  	path: '/personalCenter',
  	name: 'PersonalCenter',
    meta: {
  		title: '个人中心',
      seotitle:'个人中心_Y江湖_赢体育'
    },
		redirect: '/personalCenter/myAccount',
  	component: () => import('@/components/personalCenter/PersonalCenter.vue'),
  	children: [
			...personal
		]
  },
  /*{
  	path: '/zsvip',
  	name: 'Zsvip',
    meta: {
  		title: 'VIP数据',
      seotitle:'VIP数据_Y江湖_赢体育'
    },
  	redirect: '/Zsvip',
  	component: () => import('@/components/Zsvip.vue')
  }*/
]
